#home2{
    padding: 0 calc(15px + 85 * (100vw - 320px) / 1600);
    height: 100%;
    // background-image: url("../images/landing/home-bg.jpg");
    background-size: cover;
  .home-sec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    padding-top: 118px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 0;
  }
  @media (max-width: 991px) {
    .home-sec {
      height: 100%;
      padding: calc(132px + 26 * (100vw - 320px) / 1600) 0 calc(80px + 20 * (100vw - 320px) / 1600) 0;
    }
  }
  @media (max-width: 1500px) {
    .home-sec .home-effect {
      display: none;
    }
  }
  .home-sec .home-effect li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 20px;
    color: rgba(var(--white), 1);
    gap: 30px;
    position: relative;
    padding: 10px 0;
    position: absolute;
  }
  @media (max-width: 1700px) {
    .home-sec .home-effect li {
      font-size: 18px;
      gap: 20px;
    }
    .home-sec .home-effect li .outline-icon {
      width: 24px;
    }
  }
  .home-sec .home-effect li:first-child {
    left: -180px;
    top: 8%;
  }
  @media (max-width: 1700px) {
    .home-sec .home-effect li:first-child {
      left: -120px;
    }
  }
  .home-sec .home-effect li:nth-child(2) {
    left: -300px;
    top: 32%;
  }
  @media (max-width: 1700px) {
    .home-sec .home-effect li:nth-child(2) {
      left: -220px;
    }
  }
  .home-sec .home-effect li:nth-child(3) {
    left: -260px;
    top: 56%;
  }
  @media (max-width: 1700px) {
    .home-sec .home-effect li:nth-child(3) {
      left: -190px;
    }
  }
  .home-sec .home-effect li:nth-child(4) {
    left: -180px;
    top: 80%;
  }
  @media (max-width: 1700px) {
    .home-sec .home-effect li:nth-child(4) {
      left: -120px;
    }
  }
  .home-sec .home-effect li .effect-img {
    position: absolute;
    left: -20px;
    height: 100%;
    width: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .home-sec .home-effect li:after {
    content: "";
    position: absolute;
    left: 50px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 1px;
    height: 26px;
    background-color: rgba(var(--white), 0.2);
  }
  @media (max-width: 1700px) {
    .home-sec .home-effect li:after {
      left: 33px;
    }
  }
  .home-sec .home-content {
    width: 38%;
    padding-bottom: 50px;
  }
  @media (max-width: 1400px) {
    .home-sec .home-content {
      width: 50%;
    }
  }
  @media (max-width: 1199px) {
    .home-sec .home-content {
      width: 76%;
    }
  }
  @media (max-width: 991px) {
    .home-sec .home-content {
      width: 100%;
      padding-bottom: 0;
    }
  }
  .home-sec .home-content h2 {
    font-family: "Qwitcher Grypen", cursive;
    font-size: calc(38px + 12 * (100vw - 320px) / 1600);
    color: rgba(var(--theme-color), 1);
    padding: 0 40px;
    display: inline-block;
    position: relative;
    margin-top: -16px;
  }
  .home-sec .home-content h2:after, .home-sec .home-content h2:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 2px;
    width: 20px;
    background-color: rgba(var(--theme-color), 1);
  }
  .home-sec .home-content h2:before {
    left: unset;
    right: 0;
  }
  .home-sec .home-content h1 {
    font-family: "Play", sans-serif;
    font-size: calc(28px + 26 * (100vw - 320px) / 1600);
    line-height: 1.2;
    color: rgba(var(--title-color), 1);
  }
  .home-sec .home-content p {
    color: rgba(var(--content-color), 1);
    font-size: calc(18px + 2 * (100vw - 320px) / 1600);
    margin-bottom: 0;
  }
  .home-sec .home-content .btn-solid {
    margin-top: calc(35px + 20 * (100vw - 320px) / 1600);
  }
  .home-sec .home-img {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    height: 100%;
    padding-right: 40px;
    padding-top: 60px;
  }
  .home-img {
    position: relative;
    z-index: 1;
  }
  .home-img .robot-img {
    width: 92%;
    border-bottom: 3px solid #1565C0;
  }
  @media (max-width: 1700px) {
    .home-img .robot-img {
      width: 83%;
    }
  }
  @media (max-width: 1400px) {
    .home-img .robot-img {
      width: 72%;
    }
  }
  .home-img .round-effect {
    right: 30px;
    top: 10px;
    width: 660px;
    height: 660px;
    border: 2px dashed rgba(var(--theme-color), 1);
    padding: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 100%;
    position: absolute;
    z-index: -1;
    background-color: #0b0f1a;
  }
  @media (max-width: 1700px) {
    .home-img .round-effect {
      width: 620px;
      height: 620px;
    }
  }
  @media (max-width: 1400px) {
    .home-img .round-effect {
      width: 530px;
      height: 530px;
      right: 10px;
    }
  }
  @media (max-width: 1199px) {
    .home-img .round-effect {
      width: 400px;
      height: 400px;
    }
  }
  .home-img .round-effect:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: calc(100% - 26px);
    height: calc(100% - 26px);
    border: 4px solid #2e92ff;
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  @media (max-width: 1199px) {
    .home-img .round-effect:after {
      width: calc(100% - 20px);
      height: calc(100% - 20px);
    }
  }
  .home-img .round-effect img {
    width: 100%;
    height: 100%;
    -webkit-filter: hue-rotate(45deg);
            filter: hue-rotate(45deg);
  }
  
}

// #home2{
//       padding: 0 calc(15px + 85*(100vw - 320px) / 1600)  !important;
//       height: 100%  !important;
//       // background-image: url("/assets/images/landing/home-bg.jpg")  !important;
//       background-size: cover  !important;
//       .home-sec {
//         display: -webkit-box  !important;
//         display: -ms-flexbox  !important;
//         display: flex  !important;
//         -webkit-box-align: center  !important;
//         -ms-flex-align: center  !important;
//         align-items: center  !important;
//         height: 100%  !important;
//         padding-top: 118px  !important;
//         -webkit-box-pack: justify  !important;
//         -ms-flex-pack: justify  !important;
//         justify-content: space-between  !important;
//         padding-bottom: 0  !important;
//     }
//     .home-sec .home-img {
//       width: -webkit-max-content  !important;
//       width: -moz-max-content  !important;
//       width: max-content  !important;
//       display: -webkit-box  !important;
//       display: -ms-flexbox  !important;
//       display: flex  !important;
//       -webkit-box-align: end  !important;
//       -ms-flex-align: end  !important;
//       align-items: flex-end  !important;
//       -webkit-box-pack: end  !important;
//       -ms-flex-pack: end  !important;
//       justify-content: flex-end  !important;
//       height: 100%  !important;
//       padding-right: 40px  !important;
//       padding-top: 60px  !important;
//   }
//   .home-img {
//       position: relative  !important;
//       z-index: 1  !important;
//   }
//   .home-img .robot-img {
//       width: 92%  !important;
//       border-bottom: 3px solid #1565C0  !important;
//   }
//   @media (max-width: 1400px) {
//     .home-img .robot-img {
//         width: 72%  !important;
//     }
// }
// @media (max-width: 1700px) {
//     .home-img .robot-img {
//         width: 83%  !important;
//     }
// }
// @media (max-width: 1199px) {
//   .home-img .round-effect {
//       width: 400px  !important;
//       height: 400px  !important;
//   }
// }
// @media (max-width: 1400px) {
//   .home-img .round-effect {
//       width: 530px  !important;
//       height: 530px  !important;
//       right: 10px  !important;
//   }
// }
// @media (max-width: 1700px) {
//   .home-img .round-effect {
//       width: 620px  !important;
//       height: 620px  !important;
//   }
// }
// .home-img .round-effect {
//   right: 30px  !important;
//   top: 10px  !important;
//   width: 660px  !important;
//   height: 660px  !important;
//   border: 2px dashed rgba(var(--theme-color), 1)  !important;
//   padding: 3px  !important;
//   display: -webkit-box  !important;
//   display: -ms-flexbox  !important;
//   display: flex  !important;
//   -webkit-box-align: center  !important;
//   -ms-flex-align: center  !important;
//   align-items: center  !important;
//   -webkit-box-pack: center  !important;
//   -ms-flex-pack: center  !important;
//   justify-content: center  !important;
//   border-radius: 100%  !important;
//   position: absolute  !important;
//   z-index: -1  !important;
//   background-color: #0b0f1a  !important;
// }
// .home-img .round-effect img {
//   width: 100%  !important;
//   height: 100%  !important;
//   -webkit-filter: hue-rotate(45deg)  !important;
//   filter: hue-rotate(45deg)  !important;
// }
// @media (max-width: 1199px) {
//   .home-img .round-effect:after {
//       width: calc(100% - 20px)  !important;
//       height: calc(100% - 20px)  !important;
//   }
// }
// .home-img .round-effect:after {
//   content: ""  !important;
//   position: absolute  !important;
//   left: 50%  !important;
//   top: 50%  !important;
//   width: calc(100% - 26px)  !important;
//   height: calc(100% - 26px)  !important;
//   border: 4px solid #2e92ff  !important;
//   border-radius: 100%  !important;
//   -webkit-transform: translate(-50%, -50%)  !important;
//   transform: translate(-50%, -50%)  !important;
// }
// @media (max-width: 1500px) {
//    .home-sec .home-effect {
//       display: none  !important;
//   }
// }
// @media (max-width: 1700px) {
//   .home-sec .home-effect li:first-child {
//       left: -120px  !important;
//   }
// }
// .home-sec .home-effect li:first-child {
//   left: -180px  !important;
//   top: 8%  !important;
// }
// @media (max-width: 1700px) {
//   .home-sec .home-effect li {
//       font-size: 18px  !important;
//       gap: 20px  !important;
//   }
// }
// .home-sec .home-effect li {
//   display: -webkit-box  !important;
//   display: -ms-flexbox  !important;
//   display: flex  !important;
//   -webkit-box-align: center  !important;
//   -ms-flex-align: center  !important;
//   align-items: center  !important;
//   font-size: 20px  !important;
//   color: rgba(var(--white), 1)  !important;
//   gap: 30px  !important;
//   position: relative  !important;
//   padding: 10px 0  !important;
//   position: absolute  !important;
// }

// li {
//   list-style: none  !important;
//   display: inline-block  !important;
// }
// .home-sec .home-effect li .effect-img {
//   position: absolute  !important;
//   left: -20px  !important;
//   height: 100%  !important;
//   width: 100%  !important;
//   top: 50%  !important;
//   -webkit-transform: translateY(-50%)  !important;
//   transform: translateY(-50%)  !important;
// }
// @media (max-width: 1700px) {
//   .home-sec .home-effect li .outline-icon {
//       width: 24px  !important;
//   }
// }
// @media (max-width: 1199px) {
//    .home-sec .home-content {
//       width: 76%  !important;
//   }
// }
// @media (max-width: 1400px) {
//    .home-sec .home-content {
//       width: 50%  !important;
//   }
// }
//  .home-sec .home-content {
//   width: 38%  !important;
//   padding-bottom: 50px  !important;
// }
//  .home-sec .home-content h2 {
//   font-family: "Qwitcher Grypen", cursive  !important;
//   font-size: calc(38px + 12*(100vw - 320px) / 1600)  !important;
//   color: rgba(var(--theme-color), 1)  !important;
//   padding: 0 40px  !important;
//   display: inline-block  !important;
//   position: relative  !important;
//   margin-top: -16px  !important;
// }
// h2 {
//   font-size: calc(20px + 20*(100vw - 320px) / 1600)  !important;
//   font-weight: 600  !important;
// }
//  .home-sec .home-content h1 {
//   font-family: "Play", sans-serif  !important;
//   font-size: calc(28px + 26*(100vw - 320px) / 1600)  !important;
//   line-height: 1.2  !important;
//   color: rgba(var(--title-color), 1)  !important;
// }

// h1 {
//   font-weight: 600  !important;
//   font-size: calc(22px + 34*(100vw - 320px) / 1600)  !important;
//   line-height: 1.5  !important;
//   letter-spacing: 0.03em  !important;
// }
//  .home-sec .home-content p {
//   color: rgba(var(--content-color), 1)  !important;
//   font-size: calc(18px + 2*(100vw - 320px) / 1600)  !important;
//   margin-bottom: 0  !important;
// }
// p {
//   font-size: calc(14px + 4*(100vw - 320px) / 1600)  !important;
//   color: rgba(var(--content-color), 1)  !important;
//   font-weight: 400  !important;
// }
// }