// new theme css
.title2 h2 {
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    color: rgba(var(--theme-color), 1);
    margin-top: -10px;
}
.title2.text-center h2:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 1px;
    background-color: rgba(var(--theme-color), 1);
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -70px;
}
.title2 h2:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 1px;
    background-color: rgba(var(--theme-color), 1);
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -70px;
}
.title2 h3 {
    color: rgba(var(--content-color), 1);
    margin: 5px 0 0;
}