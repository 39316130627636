
#error__section_area {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .disconnect_wire {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.2;
    
    }
    .text_shadow{
        text-shadow: 2px 2px 1px #000000;
    }
    

    .typing_animation {
        width: 21.5ch;
        white-space: nowrap;
        overflow: hidden;
        border-right: 4px solid #212121;
        animation: cursor 1s step-start infinite, text 5s steps(18) alternate infinite;
    }


    @keyframes cursor {

        0%,
        100% {
            border-color: #212121;
        }
    }

    @keyframes text {
        0% {
            width: 0;
        }

        100% {
            width: 100%;
        }
    }


    .sleep-walker {
        position: relative;
        height: 1px;
        margin: 5rem 0 3rem;
        background-image: repeating-linear-gradient(to left, transparent 0, transparent 0.12rem, rgba(0, 0, 0, 0.15) 0.125rem, rgba(0, 0, 0, 0.15) 0.25rem);
    }

    .man {
        position: absolute;
        top: -1.4rem;
        left: 0;
        width: 1px;
        height: 2rem;
        opacity: 0.6;
        transform: scale(0.5);
        -webkit-animation: walking 50s linear infinite;
        animation: walking 50s linear infinite;
    }

    .man .head {
        position: relative;
        width: 0.5rem;
        height: 0.5rem;
        transform: translateX(-1px);
        border: 1px solid #000;
        border-radius: 50%;
    }

    .man .head::before {
        content: "";
        position: absolute;
        top: 0.28rem;
        left: 0;
        width: 170%;
        height: 1px;
        background: #000;
        transform-origin: 0% 0%;
        transform: rotate(-25deg);
    }

    .man .torso {
        position: relative;
        width: 1px;
        height: 50%;
        margin: 0 auto;
        background: #000;
    }

    .man .torso .arm-a,
    .man .torso .arm-b {
        position: absolute;
        top: 10%;
        left: 0;
        width: 100%;
        height: 85%;
        transform-origin: top center;
        background: #000;
    }

    .man .torso .arm-a::before,
    .man .torso .arm-b::before {
        content: "";
        position: absolute;
        bottom: -0.1rem;
        left: 0rem;
        width: 0.18rem;
        height: 0.18rem;
        border: 1px solid #000;
        border-radius: 50%;
    }

    .man .torso .arm-a {
        transform: rotate(-20deg);
        -webkit-animation: walk1 1.3s linear alternate infinite;
        animation: walk1 1.3s linear alternate infinite;
    }

    .man .torso .arm-b {
        transform: rotate(20deg);
        -webkit-animation: walk2 1.3s linear alternate infinite;
        animation: walk2 1.3s linear alternate infinite;
    }

    .man .legs {
        position: relative;
        width: 1px;
        height: 50%;
        margin: 0 auto;
    }

    .man .legs .leg-a,
    .man .legs .leg-b {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: top center;
        background: #000;
    }

    .man .legs .leg-a::before,
    .man .legs .leg-b::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 4px;
        height: 1px;
        background: #000;
    }

    .man .legs .leg-a {
        transform: rotate(-20deg);
        -webkit-animation: walk1 1.3s linear alternate infinite;
        animation: walk1 1.3s linear alternate infinite;
    }

    .man .legs .leg-b {
        transform: rotate(20deg);
        -webkit-animation: walk2 1.3s linear alternate infinite;
        animation: walk2 1.3s linear alternate infinite;
    }

    @-webkit-keyframes walking {
        0% {
            left: 0;
            transform: scale(0.5) rotateY(0deg);
        }

        49.9% {
            transform: scale(0.5) rotateY(0deg);
        }

        50% {
            transform: scale(0.5) rotateY(180deg);
            left: 100%;
        }

        100% {
            transform: scale(0.5) rotateY(180deg);
            left: 0;
        }
    }

    @keyframes walking {
        0% {
            left: 0;
            transform: scale(0.5) rotateY(0deg);
        }

        49.9% {
            transform: scale(0.5) rotateY(0deg);
        }

        50% {
            transform: scale(0.5) rotateY(180deg);
            left: 100%;
        }

        100% {
            transform: scale(0.5) rotateY(180deg);
            left: 0;
        }
    }

    @-webkit-keyframes walk1 {
        0% {
            transform: rotate(-20deg);
        }

        50% {
            transform: rotate(20deg);
        }
    }

    @keyframes walk1 {
        0% {
            transform: rotate(-20deg);
        }

        50% {
            transform: rotate(20deg);
        }
    }

    @-webkit-keyframes walk2 {
        0% {
            transform: rotate(20deg);
        }

        50% {
            transform: rotate(-20deg);
        }
    }

    @keyframes walk2 {
        0% {
            transform: rotate(20deg);
        }

        50% {
            transform: rotate(-20deg);
        }
    }

    @media all and (max-width: 768px) {
        header h1 {
            margin-bottom: 3rem;
        }

        .man {
            -webkit-animation-duration: 30s;
            animation-duration: 30s;
        }
    }
}