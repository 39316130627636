.cstm_btn{
    padding: 3px 9px;
    border-radius: 7px;
    background: #0a2427;
    color: #ffffff;
    font-size: 14px;
}
.fixed-navbar {
    background: #08305d;
    position: fixed;
    width: 100%;
    margin-top: -10px;
    z-index: 1;
    margin: 0 auto;
    z-index: 22;
    padding-right: 6%;
}
header{
    margin-top: 40px !important;
}